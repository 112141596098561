import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WebStorageModule } from 'ngx-store-9';
import { FooterComponent, OutdatedComponent, LoginHeaderComponent } from './@theme/index';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WebStorageModule,
  ],
  declarations: [
    FooterComponent,
    OutdatedComponent,
    LoginHeaderComponent
  ],
  exports: [
    FormsModule,
    FooterComponent,
    OutdatedComponent,
    WebStorageModule,
    LoginHeaderComponent
  ],
})
export class SharedModule { }
