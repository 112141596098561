import {
  Component,
  HostListener,
  Renderer2
} from "@angular/core";
import {
  Title
} from "@angular/platform-browser";
import {
  NavigationEnd,
  NavigationStart, Router
} from "@angular/router";
import {
  ScrollToService
} from "@nicky-lenaers/ngx-scroll-to";
import * as AOS from "aos";
import {
  filter
} from "rxjs/operators";
import {
  isQuoteAssitantAlive,
  titleCase
} from "./@core/modules";
import { environment } from '../environments/environment';

declare var $: any;

@Component({
  selector: "vc-root",
  templateUrl: "./app.component.html"
})
export class AppComponent {
  title = "app";
  public loaded = false;
  public companyname = `WeCompareDirect.com`;
  previousUrl: string;
  // spinnerShowing: boolean = true;

  public vh = window.innerHeight * 0.01;
  // setColor = {
  //   emailbackground: '#fff',
  //   errormessage:'#fff',
  // }

  siteConfig: any = null
  // siteConfig = {
  //   backgroundcolor: '#EBF0F5',
  //   primarycolor: '#FF7A00',
  //   primarycolorlight: '#FFE0C4',
  //   secondarycolor: '#553DE8',
  //   secondaryfontcolor: '#fff',
  //   primaryfontcolor: '#222222',
  //   formbackground: '#fff',
  //   companyname: null,
  //   buttoncolor: "#2b62f6"

  // };
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private _router: Router,
    private titleService: Title,
    private _scrollToService: ScrollToService,
    private renderer: Renderer2
  ) {
    if (window["SITE_CONFIG"] && location.hostname != 'localhost') {

      this.companyname = `${titleCase(window["SITE_CONFIG"].meta.brandDetails.domainName)}`;
      titleService.setTitle(this.companyname);
      if (window["SITE_CONFIG"].active == false) {
        this.favIcon.href = `${window["SITE_CONFIG"].meta.companyFavicon}`;
      } else {
        this.favIcon.href = `${environment.OCAdminURL}/api/admin/v1/get_agg_brand_favicon/${window["SITE_CONFIG"].meta.brandDetails.referrerCode}`;
      }
    } else {
      titleService.setTitle(this.companyname);
      this.favIcon.href = `https://open-comparison-storage.s3.eu-west-2.amazonaws.com/public/ocompare1606718683804.png`

    }

    document.documentElement.style.setProperty("--vh", this.vh + "px");

    //iOS: Testing
    function preventDefault(e) {
      e.preventDefault();
    }

    function disableScroll() {
      document.body.addEventListener("touchmove", preventDefault, {
        passive: false
      });
    }

    function enableScroll() {
      document.body.removeEventListener("touchmove", preventDefault);
    }
    //end iOS: Testing


    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        // Reload WoW animations when done navigating to page,
        // but you are free to call it whenever/wherever you like
        // if (isQuoteAssitantAlive()) {
        //   AOS.init({
        //     once: true,
        //     offset: -200
        //   });
        // }
        // AOS.refrshHard();
        window.scrollTo(0, 0);
        // this.triggerScrollTo();
        // this.wowService.init();
      });

    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, "bodyMinHeight");
          this.renderer.removeClass(
            document.getElementById("blueCardBgID"),
            "bodyBlueCardBg"
          );
        }
        let currentUrlSlug = event.url.slice(1);
        if (
          currentUrlSlug == "portal/login" ||
          currentUrlSlug == "portal/change-date" ||
          currentUrlSlug == "portal/request-sent" ||
          currentUrlSlug == "portal/find-policy" ||
          currentUrlSlug == "portal/modify-policy" ||
          currentUrlSlug.split(';')[0] == "portal/new-user" ||
          currentUrlSlug == "portal/old-user" ||
          currentUrlSlug == "portal/dashboard" ||
          currentUrlSlug == "portal/request-policy"
          // currentUrlSlug == ""
        ) {
          this.renderer.addClass(
            document.getElementById("blueCardBgID"),
            "bodyBlueCardBg"
          );
          this.renderer.addClass(document.body, "bodyMinHeight");
        }
        this.previousUrl = currentUrlSlug;

        if (
          currentUrlSlug == "portal/wallet" ||
          currentUrlSlug == "portal/alerts" ||
          currentUrlSlug == "portal/inbox" ||
          currentUrlSlug == "portal/more" ||
          currentUrlSlug == "portal/claims" ||
          currentUrlSlug == "portal/profile" ||
          currentUrlSlug == "portal/documents" ||
          currentUrlSlug == "portal/settings"
        ) {
          if (location.hostname.toLowerCase() == 'dashboard.myportal.co.uk') {
            window.location.href = 'https://www.myportal.co.uk'
          }
        }

      }
    });
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, "darkBackground");
        }
        let currentUrlSlug = event.url.slice(1);
        if (
          currentUrlSlug == "portal/no-policies"
          // currentUrlSlug == ""
        ) {
          this.renderer.addClass(document.body, "darkBackground");
        }
        this.previousUrl = currentUrlSlug;
      }
    });

    /* =====#ERROR PAGES====== */
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {


        if (this.previousUrl) {
          this.renderer.removeClass(document.body, "errorPages");
        }
        let currentUrlSlug = event.url.slice(1);
        if (
          currentUrlSlug == "server-error" || currentUrlSlug == "404" || currentUrlSlug == "error-page"
        ) {
          this.renderer.addClass(document.body, "errorPages");
        }
        this.previousUrl = currentUrlSlug;
      }
    });
    /* =====#END ERROR PAGES====== */

    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, "interComPosition");
        }
        let currentUrlSlug = event.url.slice(1);
        if (
          currentUrlSlug.split('/')[1] == "policy" ||
          currentUrlSlug == "portal/policies" ||
          currentUrlSlug == "portal/wallet" ||
          currentUrlSlug == "portal/verify-user" ||
          currentUrlSlug == "portal/rewards" ||
          currentUrlSlug == "portal/add-on" ||
          currentUrlSlug == "portal/no-policies" ||
          currentUrlSlug == "portal/validation"
          // currentUrlSlug == ""
        ) {
          this.renderer.addClass(document.body, "interComPosition");
        }
        this.previousUrl = currentUrlSlug;
      }
    });
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, "interComHidden");
        }
        let currentUrlSlug = event.url.slice(1);
        if (
          currentUrlSlug == "portal/app-verify-user" ||
          currentUrlSlug == "portal/app-verify-files" ||
          currentUrlSlug.split('/')[1] == "pre-payment"
          // currentUrlSlug == ""
        ) {
          this.renderer.addClass(document.body, "interComHidden");
        }
        this.previousUrl = currentUrlSlug;
      }
    });
    //INTERCOM HIDDEN MOBILE
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, "interComHidden-md");
        }
        let currentUrlSlug = event.url.slice(1);
        if (
          currentUrlSlug == "portal/add-on" || currentUrlSlug.split('/')[1] == "pre-payment" || currentUrlSlug == "portal/pay-monthly"
          // currentUrlSlug == ""
        ) {
          this.renderer.addClass(document.body, "interComHidden-md");
        }
        this.previousUrl = currentUrlSlug;
      }
    });

    this._router.events.subscribe(event => {
      let mainHTML = document.getElementsByTagName('html')[0];
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          mainHTML.removeAttribute('class');
          this.renderer.removeClass(document.body, "iphoneBody");
        }
        let currentUrlSlug = event.url.slice(1);
        if (
          currentUrlSlug == "portal/login" ||
          currentUrlSlug == "portal/change-date"
          // currentUrlSlug == ""
        ) {
          mainHTML.setAttribute('class', 'iphoneHTML');
          this.renderer.addClass(document.body, "iphoneBody");
        }
        this.previousUrl = currentUrlSlug;
      }
    });


    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let currentUrlSlug = event.url.slice(1);
        if (currentUrlSlug != "van-insurance/payments") {
          this.renderer.removeClass(document.body, "blueGradBg");
        }

        //coming soon for direct-payment
        // if (currentUrlSlug == "coming-soon") {
        //   this.renderer.addClass(document.body, "blueGradBg");
        // }
        // if (currentUrlSlug == "portal/make-payment") {
        //   this.renderer.addClass(document.body, "blueGradBg");
        // }
        // if (currentUrlSlug.split('?')[0] == "portal/make-payment") {
        //   this.renderer.addClass(document.body, "blueGradBg");
        // }
        this.renderer.removeClass(document.body, "modal-open");
        this.previousUrl = currentUrlSlug;
      }
    });
  }


  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }



  @HostListener("window:load")
  doSomething() {
    console.log('on load works')


    if (isQuoteAssitantAlive()) {
      AOS.init({
        once: true,
        offset: -200
      });
    }

    //this.savePage1();
  }

  ngOnInit() {
    //
    // this.routerSubscription = this._router.events.subscribe((event: NavigationEnd) => {
    //     if(event instanceof NavigationEnd) {
    //       window.scrollTo(0, 0);
    //     }
    //   })

    if (window["SITE_CONFIG"] && location.hostname != 'localhost') {
      this.siteConfig = window["SITE_CONFIG"].meta;
    } else {
      this.siteConfig = {
        primarycolor: "#2b62f6",
        companyFavicon: "https://open-comparison-storage.s3.eu-west-2.amazonaws.com/public/ocompare1606718683804.png",
        formbackground: "#fff",
        secondarycolor: "#2b62f6",
        backgroundcolor: "#DCECFF",
        primaryfontcolor: "#2b62f6",
        primarycolorlight: "#DCECFF",
        secondaryfontcolor: "#fff",
        secondaryheadercolor: "#dcecff"
      }
    }
    document.documentElement.style.setProperty('--background-color', this.siteConfig.backgroundcolor);
    document.documentElement.style.setProperty('--primary-color', this.siteConfig.primarycolor);//"#FF7A00"
    document.documentElement.style.setProperty('--primary-color-light', this.siteConfig.primarycolorlight); //"#FFE0C4"
    document.documentElement.style.setProperty('--secondary-color', this.siteConfig.secondarycolor);//"#553DE8"
    document.documentElement.style.setProperty('--secondary-font-color', this.siteConfig.secondaryfontcolor);//"#fff"
    document.documentElement.style.setProperty('--primary-font-color', this.siteConfig.primaryfontcolor);//"#222222"
    document.documentElement.style.setProperty('--form-background-color', this.siteConfig.formbackground);
    document.documentElement.style.setProperty('--secondary-header-color', this.siteConfig.secondaryheadercolor);
  }

  ngOnDestroy() {
    // this.routerSubscription.unsubscribe();
  }

  ngAfterViewInit() {

    $("#vc-loading").addClass("loaded");
    if ($('#loadAnimation').hasClass('loaded')) {
      $("#loadAnimation").removeClass("loaded");
      $("#loadAnimation").addClass("loaded");
    }
    // jQuery('#vc-loading').hide();
    // jQuery(document).ready(function(){
    // jQuery('#vc-loading').hide();
    // })
    // console.log("Screen width "+window.innerWidth)
    // if (!isQuoteAssitantAlive()) {
    //   AOS.init({
    //     disable: true
    //   });
    // }
    // AOS.init({
    //   once: true
    // });
  }
  ngAfterViewChecked() {
    if (!isQuoteAssitantAlive()) {
      AOS.init({
        disable: true
      });
    }
  }
}
