import * as moment from 'moment';

export function isQuoteAssitantAlive() {
  return window.innerWidth > 990;
}

export function getScrollConfig(id) {
  return {
    target: id,
    duration: 1900,
    easing: 'easeOutElastic',
    // offset: isQuoteAssitantAlive() ? -90 : 0
    offset: isQuoteAssitantAlive() ? -90 : -40
  };
}
export function updateFormsScrollConfig(id) {
  return {
    target: id,
    duration: 1900,
    easing: 'easeOutElastic',
    // offset: isQuoteAssitantAlive() ? -90 : 0
    offset: isQuoteAssitantAlive() ? -180 : -80
  };
}


export function getMonthName(month) {
  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return months[month];
};

export function getYears(from, to) {
  var years = [];
  var fromYear = from;
  while (fromYear <= to) {
    years.push(fromYear)
    fromYear++
  }
  return years.reverse();
};

export function getDatesFromTodayCurrentMonth() {
  var start = moment().startOf('month').date();
  var end = moment().endOf('month').date();

  let dates = [];
  while (start <= end) {    
    dates.push(start);
    start++
  }
  return {
    dates: Array.from(new Set(dates)),
  };
}

export function get30DatesForSelectBOX() {
  var start = new Date();
  var end = new Date();
  var newend = end.setDate(end.getDate() + 31);
  end = new Date(newend);
  var dates = [];
  var months = [];
  var years = [];
  while (start < end) {
    // console.log(new Date(start).getTime() / 1000); // unix timestamp format
    // console.log(start.toString()); // ISO Date format
    dates.push(start.getDate());
    months.push(start.getMonth()); //+1 to make jan 1
    years.push(start.getFullYear());
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  // console.log("Dates: " + Array.from(new Set(dates.sort((a, b) => a - b))));
  // console.log("Months: " + Array.from(new Set(months)));
  // console.log("Years: " + Array.from(new Set(years)));
  return {
    dates: Array.from(new Set(dates)), //To SORT Array.from(new Set(dates.sort((a, b) => a - b)))
    months: Array.from(new Set(months)),
    years: Array.from(new Set(years))
  };
}

export function get21DatesForSelectBOX() {
  var start = new Date();
  var end = new Date();
  var newend = end.setDate(end.getDate() + 21);
  end = new Date(newend);
  var dates = [];
  var months = [];
  var years = [];
  while (start < end) {
    // console.log(new Date(start).getTime() / 1000); // unix timestamp format
    // console.log(start.toString()); // ISO Date format
    dates.push(start.getDate());
    months.push(start.getMonth()); //+1 to make jan 1
    years.push(start.getFullYear());
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  // console.log("Dates: " + Array.from(new Set(dates.sort((a, b) => a - b))));
  // console.log("Months: " + Array.from(new Set(months)));
  // console.log("Years: " + Array.from(new Set(years)));
  return {
    dates: Array.from(new Set(dates)), //To SORT Array.from(new Set(dates.sort((a, b) => a - b)))
    months: Array.from(new Set(months)),
    years: Array.from(new Set(years))
  };
}

export function get5YearDatesForSelectBOX() {
  var start = new Date();
  var end = new Date();
  var newstart = start.setFullYear(end.getFullYear() - 5);
  start = new Date(newstart);
  var dates = [];
  var months = [];
  var years = [];
  while (start <= end) {
    // console.log(new Date(start).getTime() / 1000); // unix timestamp format
    // console.log(start.toString()); // ISO Date format
    dates.push(start.getDate());
    months.push(start.getMonth()); //+1 to make jan 1
    years.push(start.getFullYear());
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  // console.log("Dates: " + Array.from(new Set(dates.sort((a, b) => a - b))));
  // console.log("Months: " + Array.from(new Set(months)));
  // console.log("Years: " + Array.from(new Set(years)));
  return {
    dates: Array.from(new Set(dates.sort((a, b) => a - b))), //To SORT Array.from(new Set(dates.sort((a, b) => a - b)))
    months: Array.from(new Set(months.sort((a, b) => a - b))),
    years: Array.from(new Set(years.reverse()))
  };
}

export function getDobDatesForSelectBOX() {
  var start = new Date();
  var end = new Date();
  var newstart = start.setFullYear(1900);
  start = new Date(newstart);
  var newend = end.setFullYear(end.getFullYear() - 17);
  end = new Date(newend);
  var dates = [];
  var months = [];
  var years = [];
  while (start <= end) {
    // console.log(new Date(start).getTime() / 1000); // unix timestamp format
    // console.log(start.toString()); // ISO Date format
    dates.push(start.getDate());
    months.push(start.getMonth()); //+1 to make jan 1
    years.push(start.getFullYear());
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  // console.log("Dates: " + Array.from(new Set(dates.sort((a, b) => a - b))));
  // console.log("Months: " + Array.from(new Set(months)));
  // console.log("Years: " + Array.from(new Set(years)));
  return {
    dates: Array.from(new Set(dates.sort((a, b) => a - b))), //To SORT Array.from(new Set(dates.sort((a, b) => a - b)))
    months: Array.from(new Set(months.sort((a, b) => a - b))),
    years: Array.from(new Set(years.reverse()))
  };
}

export function getLicenseDatesForSelectBOX() {
  var start = new Date();
  var end = new Date();
  var newstart = start.setFullYear(1900);
  start = new Date(newstart);
  var newend = end.setFullYear(end.getFullYear());
  end = new Date(newend);
  var dates = [];
  var months = [];
  var years = [];
  while (start <= end) {
    // console.log(new Date(start).getTime() / 1000); // unix timestamp format
    // console.log(start.toString()); // ISO Date format
    dates.push(start.getDate());
    months.push(start.getMonth()); //+1 to make jan 1
    years.push(start.getFullYear());
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  // console.log("Dates: " + Array.from(new Set(dates.sort((a, b) => a - b))));
  // console.log("Months: " + Array.from(new Set(months)));
  // console.log("Years: " + Array.from(new Set(years)));
  return {
    dates: Array.from(new Set(dates.sort((a, b) => a - b))), //To SORT Array.from(new Set(dates.sort((a, b) => a - b)))
    months: Array.from(new Set(months.sort((a, b) => a - b))),
    years: Array.from(new Set(years.reverse()))
  };
}

export function getLicencePassDatesForSelectBOX() {
  var start = new Date();
  var end = new Date();
  var newstart = start.setFullYear(1900);
  start = new Date(newstart);
  var newend = end.setFullYear(end.getFullYear());
  end = new Date(newend);
  var dates = [];
  var months = [];
  var years = [];
  while (start <= end) {
    dates.push(start.getDate());
    months.push(start.getMonth()); //+1 to make jan 1
    years.push(start.getFullYear());
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  return {
    dates: Array.from(new Set(dates.sort((a, b) => a - b))), //To SORT Array.from(new Set(dates.sort((a, b) => a - b)))
    months: Array.from(new Set(months.sort((a, b) => a - b))),
    years: Array.from(new Set(years.reverse()))
  };
}

export function getEffectiveDatesForSelectBOX() {
  var start = new Date();
  var end = new Date();
  var newstart = start.setFullYear(end.getFullYear());
  start = new Date(newstart);
  var newend = end.setFullYear(2030);
  end = new Date(newend);
  var dates = [];
  var months = [];
  var years = [];
  while (start <= end) {
    dates.push(start.getDate());
    months.push(start.getMonth()); //+1 to make jan 1
    years.push(start.getFullYear());
    var newDate = start.setDate(start.getDate() + 1);
    start = new Date(newDate);
  }
  return {
    dates: Array.from(new Set(dates.sort((a, b) => a - b))), //To SORT Array.from(new Set(dates.sort((a, b) => a - b)))
    months: Array.from(new Set(months.sort((a, b) => a - b))),
    years: Array.from(new Set(years))
  };
}

export function pad2(number) {
  return (number < 10 ? '0' : '') + number
}
declare function unescape(s: string): string;

export function encodeObject(object) {
  return btoa(unescape(encodeURIComponent(JSON.stringify(object))));
}

export function decodeObject(object) {
  return JSON.parse(atob(object));
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function spaceremove(num) {
  return num.replace(/ /g, "");
}

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function scrollAnimation() {
  var element = document.querySelectorAll('.fade-up-animation');
  for (var i = 0; i < element.length; i++) {
    var position = element[i].getBoundingClientRect();
    if (position.top < window.innerHeight && position.bottom >= 0) {
      element[i].classList.add('scroll-animate')
    }
  }
}

export function getPaymentScrollConfig(id) {
  return {
    target: id,
    duration: 1900,
    easing: 'easeOutElastic',
    offset: isQuoteAssitantAlive() ? -260 : -150
  };
}

export function addSlashtoDate(date){
  return date.replace("-","/")
}

export function getExpiryDays(endDate,status=false) {
  
  var today = moment(new Date())
  var end = moment(endDate)
  var roundupDate = Math.ceil(moment.duration(end.diff(today)).asDays())

  if (status) {
    return roundupDate > 0 ? `ACTIVE-POLICY` : "RENEW-POLICY";
  }else{
    return roundupDate > 0 ? `${roundupDate} days until renewal` : "EXPIRED";
  }

}


export function formatDate(date){
  return moment(date).format('DD-MM-YYYY')
}