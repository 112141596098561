import { ExtraOptions, RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppPreloadingStrategy } from './@core/CustomPreloadingStrategy';
import { LoadChildrenCallback } from '@angular/router';
import { NgxStripeModule } from 'ngx-stripe'; //HERE

const routes: Routes = [
  {
    path: 'portal',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    data: {
      preload: true,
      delay: false
    }
  },
  {
    path: 'debt',
    loadChildren: () => import('./debt/debt.module').then(m => m.DebtModule),
    data: {
      preload: true,
      delay: false
    }
  },
  {
    path: 'session-expired',
    loadChildren: () => import('./@theme/components/error-pages/session-expired/session-expired.module').then(m => m.SessionExpiredModule),
    data: {
      preload: false,
      delay: false
    }
  },
  {
    path: 'error-page',
    loadChildren: () => import('./@theme/components/error-pages/error-page/error-page.module').then(m => m.ErrorPageModule),
    data: {
      preload: false,
      delay: false
    }
  },
  {
    path: 'error-page-debt',
    loadChildren: () => import('./@theme/components/error-pages/error-page-debt/error-page-debt.module').then(m => m.ErrorPageDebtModule),
    data: {
      preload: false,
      delay: false
    }
  },
  {
    path: 'session-wrong',
    loadChildren: () => import('./@theme/components/error-pages/session-wrong/session-wrong.module').then(m => m.SessionWrongModule),
    data: {
      preload: true,
      delay: true
    }
  },
  {
    path: 'unable-to-quote',
    loadChildren: () => import('./@theme/components/error-pages/unable-quote/unable-quote.module').then(m => m.UnableQuoteModule),
    data: {
      preload: true,
      delay: true
    }
  },
  {
    path: 'server-error',
    loadChildren: () => import('./@theme/components/error-pages/server-error/server-error.module').then(m => m.ServerErrorModule),
    data: {
      preload: true,
      delay: true
    }
  },
  {
    path: '404',
    loadChildren: () => import('./@theme/components/error-pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    data: {
      preload: true,
      delay: true
    }
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./@theme/components/error-pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
    data: {
      preload: true,
      delay: true
    }
  },
  { path: '', redirectTo: 'portal', pathMatch: 'full' },
  { path: '**', redirectTo: 'portal' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: AppPreloadingStrategy // <-This is our preloading
  }), NgxStripeModule],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
