import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { NgModule, Component } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AppPreloadingStrategy } from './@core/CustomPreloadingStrategy'
import { ClickOutsideModule } from 'ng-click-outside';
import { HttpClientModule } from '@angular/common/http';
import { MomentModule } from 'ngx-moment';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from "../environments/environment";

//#SLICK
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    ScrollToModule.forRoot(),
    ClickOutsideModule,
    MomentModule,
    HttpClientModule,
    SlickCarouselModule,
    NgxStripeModule.forRoot(environment?.pKeyBISL || ''),

  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    AppPreloadingStrategy
  ],
  exports: [
    ClickOutsideModule,
    NgxStripeModule
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
