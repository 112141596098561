import { ExtraOptions, Route, PreloadingStrategy } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { timer } from 'rxjs/observable/timer';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
// export class CustomPreloadingStrategy implements PreloadingStrategy  {
//
// preload(route: Route, fn: () => Observable<boolean>):Observable<boolean> {
//  return of(true).delay(5000).flatMap( (_:boolean)=>fn());
//  }
//
// }

// export class PreloadSelectedModules implements PreloadingStrategy {
//  preload(route: Route, load: Function): Observable<any> {
//     return route.data && route.data.preload ? load() : of(null);
//   }
// }


@Injectable()
export class AppPreloadingStrategy implements PreloadingStrategy {
    preload(route: Route, load: Function): Observable<any> {
        const loadRoute = (delay) => delay
            ? timer(5000).pipe(mergeMap(_ => load()))
            : load();
        return route.data && route.data.preload
            ? loadRoute(route.data.delay)
            : of(null);
      }
}
