export const environment = {
  production: true,
  siteURL: "https://mtaforms-dev.herokuapp.com/",
  mtasiteURL: "https://mtaforms.herokuapp.com/",
  vldsiteURL: "https://dev.vanlinedirect.co.uk/",
  ildsiteURL: "https://dev.insurancelinedirect.co.uk/",
  visiteURL: "https://dev.vaninsurance.com/",
  logURL: "https://convertion-tracking.herokuapp.com/",
  OCAdminURL: "https://opencomparison-admin-dev.herokuapp.com",
  paymentUrl: "https://proxy-uat.businessinsurancesolutions.co.uk",
  pKeyBISL: 'pk_test_51Lt7PdDg0f22q98rkk8mBBZXG1BvSOO7BYIzbOu9LdgoAEGRKNa0u2mH6903bFzBYLag9EADOSTtCacI3RvuJOKR005cqJCOzL',
  pKeyOCBL: 'pk_test_51NqBpSLh5y96689QaeM0tgEAldupY4IAT7rUitrZthVv5tBC5gkXFUkaXEW8rhE2jLCCzz3BF3buIzIDfLFBUG1800PrnulgQp'

  // OCAdminURL: "http://localhost:5001",
  // mtasiteURL: "http://localhost:5000/",


};
